<template>
<div>
  
  <CCard>
    <CCardBody>
		  <CRow>
			  <CCol sm="10">
				  <h2>HEADER LAPORAN</h2>
				  <p>Subheader Laporan</p>
			  </CCol>
			  <CCol sm="2" class="col align-self-center row justify-content-end">
				  <download-excel
            class="btn btn-default"
            :data="items"
            :fields="fields"
            worksheet="My Worksheet"
            name="filename.xls"
          >
            <CButton
              color="success"
              variant="outline"
              square
              size="sm"
            >
              Download Excel
            </CButton>
          </download-excel>
			  </CCol>
		  </CRow>
    </CCardBody>
  </CCard>
	
  <CCard>
  <CCardBody>
    
    <CDataTable
      :items="items"
      :fields="fields"
      column-filter
      table-filter
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
    >
      <!-- <template #status="{item}">
        <td>
          <CBadge :color="getBadge(item.status)">
            {{item.status}}
          </CBadge>
        </td>
      </template>
      <template #show_details="{item, index}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{Boolean(item._toggled) ? 'Hide' : 'Show'}}
          </CButton>
        </td>
      </template>
      <template #details="{item}">
        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody>
            <CMedia :aside-image-props="{ height: 102 }">
              <h4>
                {{item.username}}
              </h4>
              <p class="text-muted">User since: {{item.registered}}</p>
              <CButton size="sm" color="info" class="">
                User Settings
              </CButton>
              <CButton size="sm" color="danger" class="ml-1">
                Delete
              </CButton>
            </CMedia>
          </CCardBody>
        </CCollapse>
      </template> -->
    </CDataTable>
  </CCardBody>
  </CCard>
</div>
</template>

<script>
import http from '@/utils/http-common';

const items = [
  {
    visitor_id: "10023",
    visit_name: "rahmat",
    identity_number: "3212324241023",
    company: "P.T MSI",
    user_id: "rodeo",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2021-02-22 13:22",
    check_out: "2021-02-22 16:22",
    nda_status: "Valid"
  },
  {
    visitor_id: "2134",
    visit_name: "ujang",
    identity_number: "3421224243223",
    company: "P.T ABS",
    user_id: "dadang",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-02",
    activity: "Maintenance",
    check_in: "2021-02-23 13:22",
    check_out: "2021-02-23 20:22",
    nda_status: "Expired"
  },
  {
    visitor_id: "92100",
    visit_name: "asep",
    identity_number: "3421224234575",
    company: "P.T ABC",
    user_id: "djaelani",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-03",
    activity: "Shipping",
    check_in: "2021-02-24 13:22",
    check_out: "2021-02-24 22:22",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10023",
    visit_name: "Helmi Sofian",
    identity_number: "3421224234576",
    company: "P.T 123",
    user_id: "Ricky Anthony",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R07-AJ",
    activity: "Tour Site",
    check_in: "2020-06-02 08:20",
    check_out: "2020-06-02 17:22",
    nda_status: "Valid"
  },
  {
    visitor_id: "10024",
    visit_name: "Helmi Sofian",
    identity_number: "3421224234577",
    company: "P.T 124",
    user_id: "Deni Hidayat",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.2",
    location_id: "C-03",
    activity: "Problem Solving",
    check_in: "2020-06-03 12:35",
    check_out: "2020-06-03 18:41",
    nda_status: "Valid"
  },
  {
    visitor_id: "10025",
    visit_name: "Manoj Balusu",
    identity_number: "3421224234578",
    company: "P.T 125",
    user_id: "muchammad imam",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-04",
    activity: "Installation",
    check_in: "2020-06-03 15:43",
    check_out: "2020-06-03 18:44",
    nda_status: "Valid"
  },
  {
    visitor_id: "10026",
    visit_name: "Jiang Bo",
    identity_number: "3421224234579",
    company: "P.T 126",
    user_id: "Tomas Dwi",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R05-KL",
    activity: "Shipping",
    check_in: "2020-06-03 15:44",
    check_out: "2020-06-03 15:49",
    nda_status: "Valid"
  },
  {
    visitor_id: "10027",
    visit_name: "Dera Pratama",
    identity_number: "3421224234580",
    company: "P.T 127",
    user_id: "Ichsan Priambooo",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.3",
    location_id: "C-04",
    activity: "Dismantle",
    check_in: "2020-06-03 15:47",
    check_out: "2020-06-03 20:18",
    nda_status: "Valid"
  },
  {
    visitor_id: "10028",
    visit_name: "Deni Sukanto",
    identity_number: "3421224234581",
    company: "P.T 128",
    user_id: "imam santoso",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-05",
    activity: "Replace Part",
    check_in: "2020-06-03 20:21",
    check_out: "2020-06-04 01:14",
    nda_status: "Valid"
  },
  {
    visitor_id: "10029",
    visit_name: "Yudi saputro",
    identity_number: "3421224234582",
    company: "P.T 129",
    user_id: "Yudi prastowo",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R09-AS",
    activity: "Smarthand",
    check_in: "2020-06-03 20:22",
    check_out: "2020-06-04 01:15",
    nda_status: "Valid"
  },
  {
    visitor_id: "10030",
    visit_name: "Abi Faizi",
    identity_number: "3421224234583",
    company: "P.T 130",
    user_id: "muchammad imam zaenuri",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-05",
    activity: "Remove Part",
    check_in: "2020-06-03 20:24",
    check_out: "2020-06-03 20:24",
    nda_status: "Valid"
  },
  {
    visitor_id: "10031",
    visit_name: "syarip hidayat",
    identity_number: "3421224234584",
    company: "P.T 131",
    user_id: "budi setyawan",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-06",
    activity: "Meeting",
    check_in: "2020-06-03 20:25",
    check_out: "2020-06-04 01:15",
    nda_status: "Valid"
  },
  {
    visitor_id: "10032",
    visit_name: "Aditya Wibowo",
    identity_number: "3421224234585",
    company: "P.T 132",
    user_id: "Panggih Utomo",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R20-AQ",
    activity: "Replace Part",
    check_in: "2020-06-03 21:52",
    check_out: "2020-06-04 01:12",
    nda_status: "Valid"
  },
  {
    visitor_id: "10033",
    visit_name: "Jiang Bo",
    identity_number: "3421224234586",
    company: "P.T 123",
    user_id: "Agung Kriswanto",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-06",
    activity: "Smarthand",
    check_in: "2020-06-04 10:09",
    check_out: "2020-06-06 05:36",
    nda_status: "Valid"
  },
  {
    visitor_id: "2134",
    visit_name: "Yudi saputro",
    identity_number: "3421224234587",
    company: "P.T 124",
    user_id: "Deni Hidayat",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-01",
    activity: "Remove Part",
    check_in: "2020-06-04 11:34",
    check_out: "2020-06-04 11:44",
    nda_status: "Valid"
  },
  {
    visitor_id: "2135",
    visit_name: "Abi Faizi",
    identity_number: "3421224234588",
    company: "P.T 125",
    user_id: "Andri Riyadi",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Meeting",
    check_in: "2020-06-04 12:51",
    check_out: "2020-06-04 17:02",
    nda_status: "Valid"
  },
  {
    visitor_id: "2136",
    visit_name: "Deni Sukanto",
    identity_number: "3421224234589",
    company: "P.T 126",
    user_id: "Muhammad Putra",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.2",
    location_id: "C-07",
    activity: "Replace Part",
    check_in: "2020-06-04 12:52",
    check_out: "2020-06-04 17:03",
    nda_status: "Valid"
  },
  {
    visitor_id: "2137",
    visit_name: "MUHAMMAD FACHGOZY",
    identity_number: "3421224234590",
    company: "P.T 127",
    user_id: "DIDIEK BUDHI PRASETYO",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-08",
    activity: "Smarthand",
    check_in: "2020-06-04 12:52",
    check_out: "2020-06-04 17:02",
    nda_status: "Valid"
  },
  {
    visitor_id: "2138",
    visit_name: "Hendro Wahyudi",
    identity_number: "3421224234591",
    company: "P.T 128",
    user_id: "Irpan Supian",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R10-AP",
    activity: "Remove Part",
    check_in: "2020-06-04 13:15",
    check_out: "2020-06-04 20:07",
    nda_status: "Valid"
  },
  {
    visitor_id: "2139",
    visit_name: "Dicki Lesmana",
    identity_number: "3421224234592",
    company: "P.T 129",
    user_id: "davian yudhiroka",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-08",
    activity: "Meeting",
    check_in: "2020-06-04 13:15",
    check_out: "2020-06-04 20:07",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2140",
    visit_name: "Lalan Tarlan",
    identity_number: "3421224234593",
    company: "P.T 130",
    user_id: "Aditya Nugraha",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-09",
    activity: "Installation",
    check_in: "2020-06-04 13:56",
    check_out: "2020-06-04 23:12",
    nda_status: "Expired"
  },
  {
    visitor_id: "2141",
    visit_name: "Jati Indrawan",
    identity_number: "3421224234594",
    company: "P.T 131",
    user_id: "Tony Ginting",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AY",
    activity: "Installation",
    check_in: "2020-06-04 13:56",
    check_out: "2020-06-04 23:12",
    nda_status: "Expired"
  },
  {
    visitor_id: "2142",
    visit_name: "Akhmad Mursidi",
    identity_number: "3421224234595",
    company: "P.T 132",
    user_id: "Aditya Nugraha",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-09",
    activity: "Installation",
    check_in: "2020-06-04 15:09",
    check_out: "2020-06-04 20:07",
    nda_status: "Expired"
  },
  {
    visitor_id: "2143",
    visit_name: "Gerry Ardianto",
    identity_number: "3421224234596",
    company: "P.T 123",
    user_id: "Tony Ginting",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-10",
    activity: "Installation",
    check_in: "2020-06-04 15:09",
    check_out: "2020-06-04 20:03",
    nda_status: "Expired"
  },
  {
    visitor_id: "2144",
    visit_name: "Brian Muhammad",
    identity_number: "3421224234597",
    company: "P.T 124",
    user_id: "Fransiskus Hernowo",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2020-06-04 18:07",
    check_out: "2020-06-04 20:55",
    nda_status: "Expired"
  },
  {
    visitor_id: "2145",
    visit_name: "Roni Wijaya",
    identity_number: "3421224234598",
    company: "P.T 125",
    user_id: "david ruslim",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-10",
    activity: "Installation",
    check_in: "2020-06-05 09:10",
    check_out: "2020-06-05 20:27",
    nda_status: "Expired"
  },
  {
    visitor_id: "2146",
    visit_name: "Muhammad Naufal",
    identity_number: "3421224234599",
    company: "P.T 126",
    user_id: "Irpan Supian",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-11",
    activity: "Remove Part",
    check_in: "2020-06-05 10:10",
    check_out: "2020-06-05 20:27",
    nda_status: "Expired"
  },
  {
    visitor_id: "2147",
    visit_name: "Deny Ridwan",
    identity_number: "3421224234600",
    company: "P.T 127",
    user_id: "Deny Ridwan",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Remove Part",
    check_in: "2020-06-05 10:12",
    check_out: "2020-06-05 20:33",
    nda_status: "Expired"
  },
  {
    visitor_id: "2148",
    visit_name: "Dhany Triyadi",
    identity_number: "3421224234601",
    company: "P.T 128",
    user_id: "Erwin Fajar Maulana",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-11",
    activity: "Remove Part",
    check_in: "2020-06-05 10:32",
    check_out: "2020-06-05 18:21",
    nda_status: "Expired"
  },
  {
    visitor_id: "2149",
    visit_name: "Alfin Bahtiar",
    identity_number: "3421224234602",
    company: "P.T 129",
    user_id: "Nyoman Wideyana",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-12",
    activity: "Remove Part",
    check_in: "2020-06-05 10:33",
    check_out: "2020-06-05 20:38",
    nda_status: "Expired"
  },
  {
    visitor_id: "2150",
    visit_name: "Grasias Raflesonova",
    identity_number: "3421224234603",
    company: "P.T 130",
    user_id: "Muhammad Hafiz",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2020-06-05 11:02",
    check_out: "2020-06-05 16:57",
    nda_status: "Expired"
  },
  {
    visitor_id: "2151",
    visit_name: "Samsul Maarif",
    identity_number: "3421224234604",
    company: "P.T 131",
    user_id: "fajar andriyanto",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-12",
    activity: "Installation",
    check_in: "2020-06-05 11:04",
    check_out: "2020-06-05 13:51",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2152",
    visit_name: "Tedhi Achdiana",
    identity_number: "3421224234605",
    company: "P.T 132",
    user_id: "Erwin Maulana",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-13",
    activity: "Meeting",
    check_in: "2020-06-05 15:58",
    check_out: "2020-06-05 20:34",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2153",
    visit_name: "Dicki Lesmana",
    identity_number: "3421224234606",
    company: "P.T 123",
    user_id: "fajar andriyanto",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Meeting",
    check_in: "2020-06-05 20:00",
    check_out: "2020-06-05 16:55",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2154",
    visit_name: "Lalan Tarlan",
    identity_number: "3421224234607",
    company: "P.T 124",
    user_id: "Nyoman Wideyana",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-13",
    activity: "Installation",
    check_in: "2020-06-05 20:00",
    check_out: "2020-06-06 10:13",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2155",
    visit_name: "Cepi Hendri Setiadi",
    identity_number: "3421224234608",
    company: "P.T 125",
    user_id: "Muhammad Hafizh",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-14",
    activity: "Remove Part",
    check_in: "2020-06-05 20:38",
    check_out: "2020-06-06 10:15",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2156",
    visit_name: "Rikie Bastomi",
    identity_number: "3421224234609",
    company: "P.T 126",
    user_id: "Bambang SRIGATI",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Remove Part",
    check_in: "2020-06-06 10:03",
    check_out: "2020-06-06 10:15",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2157",
    visit_name: "M.Farid Adi",
    identity_number: "3421224234610",
    company: "P.T 127",
    user_id: "Marcellius Wijaya",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-14",
    activity: "Installation",
    check_in: "2020-06-06 10:04",
    check_out: "2020-06-06 15:10",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2158",
    visit_name: "Akhmad Mukhammad",
    identity_number: "3421224234611",
    company: "P.T 128",
    user_id: "Widi Asmoro",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-15",
    activity: "Installation",
    check_in: "2020-06-08 10:25",
    check_out: "2020-06-06 15:10",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2159",
    visit_name: "Gifari Kautsar",
    identity_number: "3421224234612",
    company: "P.T 129",
    user_id: "Ajik Purnomo",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2020-06-08 10:32",
    check_out: "2020-06-08 17:02",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2160",
    visit_name: "Ade Pramono",
    identity_number: "3421224234613",
    company: "P.T 130",
    user_id: "Oka gurning",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-15",
    activity: "Installation",
    check_in: "2020-06-08 10:32",
    check_out: "2020-06-08 18:14",
    nda_status: "Expired"
  },
  {
    visitor_id: "2161",
    visit_name: "Kuswahyudi utomo",
    identity_number: "3421224234614",
    company: "P.T 131",
    user_id: "Muhammad Amirullah",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-16",
    activity: "Shipping",
    check_in: "2020-06-08 11:03",
    check_out: "2020-06-08 18:13",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2162",
    visit_name: "Rifky Saputra",
    identity_number: "3421224234615",
    company: "P.T 132",
    user_id: "Eka Irawan",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Tour Site",
    check_in: "2020-06-08 13:24",
    check_out: "2020-06-08 18:09",
    nda_status: "Valid"
  },
  {
    visitor_id: "2163",
    visit_name: "Muhamad Sandhi",
    identity_number: "3421224234616",
    company: "P.T 163",
    user_id: "Abi Faizi",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-16",
    activity: "Problem Solving",
    check_in: "2020-06-08 13:24",
    check_out: "2020-06-08 19:23",
    nda_status: "Invalid"
  },
  {
    visitor_id: "2164",
    visit_name: "Muhammad Amirullah",
    identity_number: "3421224234617",
    company: "P.T 164",
    user_id: "Achmad naufal",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-17",
    activity: "Installation",
    check_in: "2020-06-08 14:50",
    check_out: "2020-06-08 19:23",
    nda_status: "Invalid"
  },
  {
    visitor_id: "92100",
    visit_name: "Abi Faizi",
    identity_number: "3421224234618",
    company: "P.T 165",
    user_id: "Mohammad Wijaya",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2020-06-08 14:51",
    check_out: "2020-06-08 19:23",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092100",
    visit_name: "Gerry Ardianto",
    identity_number: "3421224234619",
    company: "P.T 166",
    user_id: "Raden Andhika P",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-17",
    activity: "Problem Solving",
    check_in: "2020-06-08 22:22",
    check_out: "2020-06-08 19:22",
    nda_status: "Expired"
  },
  {
    visitor_id: "10092101",
    visit_name: "Dicky Soeharto",
    identity_number: "3421224234620",
    company: "P.T 167",
    user_id: "Nana Sukron",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-18",
    activity: "Installation",
    check_in: "2020-06-09 09:11",
    check_out: "2020-06-09 00:30",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092102",
    visit_name: "Fahmi Kurniawan",
    identity_number: "3421224234621",
    company: "P.T 168",
    user_id: "Ineke Zumara",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Installation",
    check_in: "2020-06-09 09:21",
    check_out: "2020-06-09 14:00",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092103",
    visit_name: "Ade Pramono",
    identity_number: "3421224234622",
    company: "P.T 169",
    user_id: "David Ruslim",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-18",
    activity: "Remove Part",
    check_in: "2020-06-09 09:23",
    check_out: "2020-06-09 14:00",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092104",
    visit_name: "Deny Ridwan",
    identity_number: "3421224234623",
    company: "P.T 170",
    user_id: "Ajik Purnomo",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-19",
    activity: "Remove Part",
    check_in: "2020-06-09 09:34",
    check_out: "2020-06-09 14:00",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092105",
    visit_name: "Muhammad Amirullah",
    identity_number: "3421224234624",
    company: "P.T 171",
    user_id: "Oka gurning",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:04",
    check_out: "2020-06-09 20:29",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092106",
    visit_name: "Abi Faizi",
    identity_number: "3421224234625",
    company: "P.T 172",
    user_id: "Muhammad Amirullah",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-19",
    activity: "Maintenance",
    check_in: "2020-06-09 10:05",
    check_out: "2020-06-09 15:37",
    nda_status: "Expired"
  },
  {
    visitor_id: "10092107",
    visit_name: "Haryanto Wibowo",
    identity_number: "3421224234626",
    company: "P.T 173",
    user_id: "Eka Irawan",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-20",
    activity: "Shipping",
    check_in: "2020-06-09 10:40",
    check_out: "2020-06-09 20:53",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092108",
    visit_name: "Ineke Zumara",
    identity_number: "3421224234627",
    company: "P.T 174",
    user_id: "Abi Faizi",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:43",
    check_out: "2020-06-09 15:56",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092109",
    visit_name: "Raden Andhika P",
    identity_number: "3421224234628",
    company: "P.T 175",
    user_id: "Achmad naufal",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-20",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:44",
    check_out: "2020-06-09 15:28",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092110",
    visit_name: "Achmad naufal",
    identity_number: "3421224234629",
    company: "P.T 176",
    user_id: "Mohammad Wijaya",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-21",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:49",
    check_out: "2020-06-09 15:29",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092111",
    visit_name: "Mohammad Wijaya",
    identity_number: "3421224234630",
    company: "P.T 177",
    user_id: "Raden Andhika P",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:50",
    check_out: "2020-06-09 15:36",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092112",
    visit_name: "Raja Lubis",
    identity_number: "3421224234631",
    company: "P.T 178",
    user_id: "Nana Sukron",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-21",
    activity: "Problem Solving",
    check_in: "2020-06-09 10:54",
    check_out: "2020-06-10 00:37",
    nda_status: "Expired"
  },
  {
    visitor_id: "10092113",
    visit_name: "Deni Hidayat",
    identity_number: "3421224234632",
    company: "P.T 179",
    user_id: "Ineke Zumara",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-22",
    activity: "Installation",
    check_in: "2020-06-09 11:08",
    check_out: "2020-06-09 13:44",
    nda_status: "Invalid"
  },
  {
    visitor_id: "10092114",
    visit_name: "Muchammad Zaenuri",
    identity_number: "3421224234633",
    company: "P.T 180",
    user_id: "David Ruslim",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Remove Part",
    check_in: "2020-06-09 11:12",
    check_out: "2020-06-09 11:55",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092115",
    visit_name: "Andri Riyadi",
    identity_number: "3421224234634",
    company: "P.T 181",
    user_id: "Oka gurning",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-22",
    activity: "Remove Part",
    check_in: "2020-06-09 11:13",
    check_out: "2020-06-09 11:54",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092116",
    visit_name: "Muhammad Putra",
    identity_number: "3421224234635",
    company: "P.T 182",
    user_id: "Muhammad Amirullah",
    site_id: "DC JATILUHUR",
    rooms_id: "Data hall 1B",
    location_id: "Cage-23",
    activity: "Problem Solving",
    check_in: "2020-06-09 11:17",
    check_out: "2020-06-09 20:46",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092117",
    visit_name: "Agung Kriswanto",
    identity_number: "3421224234636",
    company: "P.T 183",
    user_id: "Eka Irawan",
    site_id: "DC TAMAN TEKNO",
    rooms_id: "Data hall 1A",
    location_id: "R01-AB",
    activity: "Maintenance",
    check_in: "2020-06-09 11:17",
    check_out: "2020-06-10 00:37",
    nda_status: "Valid"
  },
  {
    visitor_id: "10092118",
    visit_name: "Panggih Utomo",
    identity_number: "3421224234637",
    company: "P.T 184",
    user_id: "Abi Faizi",
    site_id: "DC TB SIMATUPANG",
    rooms_id: "Data hall lt.1",
    location_id: "C-23",
    activity: "Shipping",
    check_in: "2020-06-09 11:18",
    check_out: "2020-06-09 11:54",
    nda_status: "Valid"
  }
];

const fields = [
  /* { key: 'username', _style:'min-width:200px' },
  'registered',
  { key: 'role', _style:'min-width:100px;' },
  { key: 'status', _style:'min-width:100px;' },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  } */
  {key: 'visitor_id', label: 'ID', filter: false},
  {key: 'visit_name', label: 'Visitor'},
  {key: 'identity_number', label: 'ID Card', filter: false},
  'company',
  {key: 'user_id', filter: false},
  {key: 'site_id', label: 'Data Center'},
  {key: 'rooms_id', label: 'Room'},
  {key: 'location_id', label: 'Floor'},
  'activity',
  {key: 'check_in', filter: false},
  {key: 'check_out', filter: false},
  {key: 'nda_status', label: 'NDA Status'},
];

export default {
  name: 'Report',
  data () {
    return {
      items: items.map((item, id) => { return {...item, id}}),
      fields,
      details: [],
      collapseDuration: 0
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    }
  }
}
</script>